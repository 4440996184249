button,
input[type="submit"] {
  @extend %button;
  @include appearance(none);
  border: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
