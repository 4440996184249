@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Rokkitt:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);
@import url(https://fonts.googleapis.com/css?family=Roboto:300italic);

$old_sans-serif-font-family: Montserrat, Trebuchet, sans-serif;
$old_serif-font-family: Montserrat, Trebuchet, sans-serif; //Vollkorn, Times, serif;
$old_slab-font-family: Rokkitt, Trebuchet, sans-serif;

$condensed-font-family: 'Roboto Condensed', Montserrat, sans-serif;
$light-condensed-font-family: 'Roboto Condensed', Montserrat, sans-serif;
$italicized-font-family: Roboto, Montserrat, sans-serif;

$sans-serif-font-family: Roboto, Trebuchet, sans-serif;
$serif-font-family: Roboto, Trebuchet, sans-serif; //Vollkorn, Times, serif;
$slab-font-family: Roboto Slab, Trebuchet, sans-serif;

$page-title-font-family: $condensed-font-family;
$page-title-font-weight: 900;
$page-title-font-style: normal;
$page-title-font-size: $base-font-size * 3.5;

$content-title-font-family: $slab-font-family;
$content-title-font-weight: 400;
$content-title-font-style: normal;

$page-content-font-family: $serif-font-family;
$page-content-font-weight: 300;
$page-content-font-style: light;
$page-content-font-size: 1.1em;

//$footer-content-font-family: $sans-serif-font-family;
$footer-content-font-family: $light-condensed-font-family;
$footer-content-font-weight: 400;
$footer-content-font-style: normal;
$footer-content-font-size: .7em;

$navigation-button-font-family: $condensed-font-family;
$navigation-button-font-weight: 700;
$navigation-button-font-style: bold;
$navigation-button-font-size: $base-font-size * 1.0;


body {
  -webkit-font-smoothing: antialiased;
  background-color: $base-background-color;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $unitless-line-height;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
  clear: left;
}

h1, h2, h3, h4, h5, h5 {
  font-family: $content-title-font-family;
  font-weight: $content-title-font-weight;
  font-style: $content-title-font-style; 
  color:#2C3E50;
  margin-top: 15px;
  margin-bottom: 10px;
  //padding-left: 5px;
  padding-bottom: 0px;
}

h1 {
  //font-size: $base-font-size * 1.75; // 16 * 2.25 = 36px
  font-size: $base-font-size * 1.3; 
  //border-bottom: 2px $light-gray solid;
}

h2 {
  font-size: $base-font-size * 1.5; // 16 * 2 = 32px
  border-bottom: 2px $light-gray solid;
}

h3 {
  font-size: $base-font-size * 1.15; // 16 * 1.75 = 28px
}

h4 {
  font-size: $base-font-size * 1.1; // 16 * 1.5 = 24px
}

h5 {
  font-size: $base-font-size * 1.05; // 16 * 1.25 = 20px
}

h6 {
  font-size: $base-font-size;
}

p {
  margin: 0 0 ($base-line-height * .5);
  color:#2B2F33;
}

a {
  @include transition(color 0.1s linear);
  color: $base-link-color;
  text-decoration: none;

  &:hover {
    color: $hover-link-color;
  }

  &:active, &:focus {
    color: $hover-link-color;
    outline: none;
  }
}

hr {
  border-bottom: 1px solid $base-border-color;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-line-height 0;
}

img {
  margin: 0;
  max-width: 100%;
}

blockquote {
  border-left: 2px solid $base-border-color;
  color: lighten($base-font-color, 15);
  margin: $base-line-height 0;
  padding-left: calc($base-line-height / 2);
}

cite {
  color: lighten($base-font-color, 25);
  font-style: italic;

  &:before {
    content: '\2014 \00A0';
  }
}

.page-header h1 {
  font-family: $page-title-font-family;
  font-weight: $page-title-font-weight;
  font-style: $page-title-font-style;  
  font-size: $base-font-size * 3; // 16 * 3 = 48px
  color: $blue2;
}
