ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: calc($base-line-height / 2);
    padding-left: $base-line-height;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: calc($base-line-height / 2);
    padding-left: $base-line-height;
  }
}


@mixin icon-list($color, $icon) {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding-left: 2em;
      padding-bottom: 0.5em;
      line-height: $base-font-size * 1.2;
      vertical-align: top;
      color: $base-font-color;
      font-family: $sans-serif-font-family;
      font-weight: 400;
      font-size: $base-font-size * 0.9;
      font-style: normal;
      min-height: 2.5em;

      a {        
        font-weight: 700;
        color: darken( $color, 20% );
      }
      a:after{
         border-bottom-width: 0;
      }    
    }
    li:before {
      content: $icon;
      color: $color; 
      font-family: FontAwesome; /* or whatever */
      font-size: $base-font-size * 1.3;      
      margin-left: -2.2em;
      padding-left: 0.8em;
      width: 1.25em;
      height: 1.5em;
      float: left;
      display: block;
    }  
  }
  
  
  @mixin reverse-dotted-link-underline() {
    a {
  	-moz-transition: border-bottom-color 0.2s ease-in-out;
  	-webkit-transition: border-bottom-color 0.2s ease-in-out;
  	-ms-transition: border-bottom-color 0.2s ease-in-out;
  	transition: border-bottom-color 0.2s ease-in-out;
      //color: #444;
      //color: $blue2;
      position: relative;
      text-decoration: none;
  	border-bottom: dotted 1px transparent;
    }
    a:hover {
  	border-bottom-color: #E64C3C;
    }
  } 
  	  
  @mixin pub-list($color) {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        padding-left: -0.1em;
        padding-bottom: 0.2em;
        line-height: $base-font-size * 1.2;
        vertical-align: top;
		//text-align:right;
        color: $base-font-color;
        font-family: $condensed-font-family;
        font-weight: 400;
        font-size: $base-font-size * 0.9;
        font-style: normal;
        min-height: 2.5em;
		
	    @include reverse-dotted-link-underline();
        a {        
          font-weight: 700;
          //color: darken( $color, 20% );
  		  color: $color;
        }
//        a:after{
//           border-bottom-width: 0;
//        }
		.right-text{
			text-align:right;
		}    
      }
    }
 
    @mixin no-icon-list($color) {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          padding-left: -0.1em;
          //padding-bottom: 0.5em;
          line-height: $base-font-size * 1.2;
          vertical-align: top;
  		//text-align:right;
          color: $base-font-color;
          font-family: $condensed-font-family;
          font-weight: 400;
          font-size: $base-font-size * 0.9;
          font-style: normal;
          min-height: 2.5em;
		  
	      a:after{
	         border-bottom-width: 0;
	      }
  	  }
      }
	  
      @mixin no-icon-list-images($color) {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            padding-left: -0.1em;
            //padding-bottom: 0.5em;
            line-height: $base-font-size * 1.2;
            vertical-align: top;
    		text-align:center;
//            color: $base-font-color;
//            font-family: $condensed-font-family;
//            font-weight: 400;
//            font-size: $base-font-size * 0.9;
//            font-style: normal;
            min-height: 2.5em;
		  a{
		  	  border-bottom: none;
		  }
  	      a:after{
  	         border-bottom-width: 0;
  	      }
    	  }
        } 
	   

ul.project-icon-list {
  @include icon-list($project-color,"\f013");
}

ul.release-icon-list {
  @include icon-list($download-color,"\f019");
}

ul.publication-icon-list {
  //@include icon-list($publication-color,"\f02d");
  @include pub-list($recent-publications-color);
}

ul.institution-icon-list {
  @include no-icon-list-images($team-color);  
}

ul.person-icon-list {
  @include icon-list($team-color,"\f007");
}

ul.funding-icon-list {
  @include icon-list($neutral-color,"\f19c");
}

ul.source-icon-list {
  @include icon-list($neutral-color,"\f09b");
}



/*
 ul.project-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      padding-left: 2.5em ;
      line-height: $base-font-size * 2;
      vertical-align: middle;
      color: $project-color;
      font-family: $sans-serif-font-family;
      font-weight: 700;
      font-size: $base-font-size;
      font-style: normal;

      a {        
        color: $project-color;
      }
    }
    li:before {
      content: "\f013";
      color: $project-color;      
      font-family: FontAwesome;
      display: inline-block;
      margin-left: -2em;
      padding-left: 0.35em;
      margin-right: 0.5em;
      width: 1.25em;
      height: 1.5em;
    }  
  }
*/

dl {
  margin-bottom: calc($base-line-height / 2);

  dt {
    font-weight: bold;
    margin-top: calc($base-line-height / 2);
  }

  dd {
    margin: 0;
  }
}

