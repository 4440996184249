// Typography
$sans-serif: $helvetica;
$serif: $georgia;
$base-font-family: $sans-serif;

// Sizes
$base-font-size: 1em;
$base-line-height: $base-font-size * 1.4;
$unitless-line-height: strip-units($base-line-height);
$title-line-height: $base-font-size * 1.25;
$base-border-radius: em(3);


// Colors
//$blue:#3376A0;
$blue: #477DCA;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #DDD;
$light-red: #FBE3E4;
$light-yellow: #FFF6BF;
$light-green: #E6EFC2;
$blue2:#3376A0;
$dark-blue:#2C3E50;

//$home-color: #AD3A33;
$home-color: #C03A2B;
//$home-color: #149747;
//$team-color: #D44942;
$team-color:#3376A0;
//$team-color: #3DB7DD;
$project-color: #EB0021;
$download-color: #F36229;

//$publication-color: #D66157;
//$publication-color: #3376A0;
//$publication-color: #48A5D3;
$publication-color: #E58035;
$recent-publications-color:#3376A0; //this is being darkened somewhere...
//$publication-color: #4CB0D8;
//$publication-color: #FDB938;
$presentation-color: $download-color;
$help-color: #B2B2B2;
$neutral-color: $help-color;


// Background Color
$base-background-color: white;
$base-body-color: white;

// Font Colors
$base-font-color: $dark-gray;
$base-accent-color: $blue;

// Link Colors
$base-link-color: $base-accent-color;
$hover-link-color: darken($base-accent-color, 15);
$base-button-color: $base-link-color;
$hover-button-color: $hover-link-color;

// Border color
$base-border-color: $light-gray;

// Flash Colors
$alert-color: $light-yellow;
$error-color: $light-red;
$notice-color: $light-yellow;
$success-color: $light-green;

// Forms
$form-border-color: $base-border-color;
$form-border-color-hover: darken($base-border-color, 10);
$form-border-color-focus: $base-accent-color;
$form-border-radius: $base-border-radius;
$form-box-shadow: inset 0 1px 3px rgba(0,0,0,0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);
$form-font-size: $base-font-size;
$form-font-family: $base-font-family;
