.cards {
  @include clearfix;
}

.card {
  $card-border-color: $base-border-color;
  $card-border: 1px solid $card-border-color;
  $card-background: lighten($card-border-color, 10);
  $card-header-background: $card-background;
  $card-hover-background: lighten($card-background, 5);
  $card-image-background: #fff;
  $card-image-hover-background: lighten($card-image-background, 5);
  $card-stats-color: lighten($base-accent-color, 10);

  min-height: 350px;

  @include transition (all 0.2s ease-in-out);
  background-color: $card-background;
  border-radius: $base-border-radius;
  border: $card-border;
  margin-bottom: $base-line-height;
  cursor: pointer;
  box-shadow: 0 2px 4px darken($base-body-color, 10);
  position: relative;

  @include media($large-screen) {
    @include span-columns(4);  
    @include omega(3n);
  }

  .card-image {
    overflow: hidden;
    max-height: 200px;

    img {
      width: 100%;
      @include transition (all 0.2s ease-in-out);
      background: $card-image-background;
      border-top-left-radius: $base-border-radius;
      border-top-right-radius: $base-border-radius;
    }
  }

  .ribbon-wrapper {
    $ribbon-size: 85px;
    $ribbon-background: $base-accent-color;
    @include size($ribbon-size);
    overflow: hidden;
    position: absolute;
    top: -1px;
    right: -1px;

    .ribbon {
      font-size: .8em;
      font-weight: 800;
      text-align: center;
      @include transform(rotate(45deg));
      position: relative;
      padding: 2px 7px;
      left: -5px;
      top: 15px;
      width: 120px;
      background-color: $ribbon-background;
      color: white;
      box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    }
  }

  @mixin card-header($color) {
    @include transition (all 0.2s ease-in-out);
    background-color: $card-header-background;
    border-bottom: $card-border;
    border-radius: $base-border-radius $base-border-radius 0 0;
    padding: calc($base-line-height/3) calc($gutter/2);

    a {
      display: block;
      font-family: $sans-serif-font-family;
      font-weight: 700;
      color: $color;      
    }

   &:hover {
      background-color: tint( $color, 95% );//$card-hover-background;
    }    
  }

  .card-header-project {
    @include card-header($project-color);
  }

  .card-header-funding {
    @include card-header($neutral-color);
  }

  .card-header {
    @include card-header($neutral-color);
  }

  .card-copy {
    padding: calc($base-line-height/2) calc($gutter/2);
    font-size: .9em;
    line-height: 1.5em;
  }

  .card-stats {
    padding: calc($base-line-height/2) calc($gutter/2);
    overflow: auto;

    ul li {
      display: inline;
      color: $card-stats-color;
      font-weight: 800;
      font-size: 1.2em;
      float: left;
      border-right: 1px solid transparentize($base-font-color, .8);
      line-height: 1.1em;
      padding: 0 .7em 0 .7em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      span {
        color: $base-font-color;
        font-size: .7em;
        display: block;
        font-weight: normal;
      }
    }
  }

  &:hover {
    background-color: $card-hover-background;
    
    .card-image img {
      background: $card-image-hover-background;
    }
  
    .card-header {
      background-color: $card-hover-background;
    }
  }

  &:active {
    background-color: $card-background;

    .card-header {
      background-color: $card-background;
    }
  }
}