fieldset {
  background: lighten($base-border-color, 10);
  border: 1px solid $base-border-color;
  margin: 0 0 (calc($base-line-height / 2)) 0;
  padding: $base-line-height;
}

input,
label,
select {
  display: block;
  font-family: $form-font-family;
  font-size: $form-font-size;
}

label {
  font-weight: bold;
  margin-bottom: calc($base-line-height / 4);

  &.required:after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

textarea,
#{$all-text-inputs},
select[multiple=multiple] {
  @include box-sizing(border-box);
  @include transition(border-color);
  background-color: white;
  border-radius: $form-border-radius;
  border: 1px solid $form-border-color;
  box-shadow: $form-box-shadow;
  font-family: $form-font-family;
  font-size: $form-font-size;
  margin-bottom: calc($base-line-height / 2);
  padding: (calc($base-line-height / 3)) (calc($base-line-height / 3));
  width: 100%;

  &:hover {
    border-color: $form-border-color-hover;
  }

  &:focus {
    border-color: $form-border-color-focus;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
}

input[type="checkbox"], input[type="radio"] {
  display: inline;
  margin-right: calc($base-line-height / 4);
}

input[type="file"] {
  margin-bottom: calc($base-line-height / 2);
  padding-bottom: (calc($base-line-height / 3));
  width: 100%;
}

select {
  width: auto;
  max-width: 100%;
  margin-bottom: $base-line-height;
}
